<template>
  <section
    v-if="order"
    id="main"
    class="position-absolute w-100"
    style="padding: 22mm; border: 1px dashed black"
  >
    <table>
      <tr>
        <th
          class="packing-slip px-1 pt-0"
          style="color: #d89700; font-size: 2.8rem"
        >
          PACKING SLIP
        </th>
        <th class="text-right"># {{ order.order_number }}</th>
      </tr>
      <tr>
        <td v-if="order.pick_up_point_address">
          <p>
            <b>
              [Pick-up to]:<br />
              {{ order.pick_up_point_name }}<br />
              {{ order.pick_up_point_address }}
            </b>
          </p>
        </td>
        <td v-else>
          <p>
            <b>[Ship to]: {{ order.shipping_address.name }}</b
            ><br />
            {{ order.shipping_address.address }}<br />
            {{ order.shipping_address.city }},
            {{ order.shipping_address.districts }},<br />
            {{ order.shipping_address.postal_code }}<br />
            +62{{ order.shipping_address.phone }}
          </p>
        </td>
        <td class="align-top" align="right">
          <b>Service</b><br />
          {{
            order.pick_up_point_address
              ? "Pickup Point Service"
              : order.courier_name + " - " + order.courier_service_code
          }}
          <table class="p-0 text-right" style="width: fit-content">
            <tr>
              <td>Order Date :</td>
              <td>
                {{ order.date }}
              </td>
            </tr>
            <tr>
              <td>Shipping :</td>
              <td>Rp{{ order.shipping.toLocaleString("in") }}</td>
            </tr>
          </table>
        </td>
      </tr>
      <tr v-if="order.pick_up_point_platform_name && order.pick_up_point_platform_name != 'OWN'">
        <td colspan="2">
          <div class="border border-danger text-danger bold p-1 d-inline-block mb-1 text-uppercase">
            <font-awesome icon="exclamation-circle" /> Mohon dikirim saat jam operasional outlet (Pk 09.00 - 15.00)
          </div>
        </td>
      </tr>
    </table>
    <table>
      <thead>
        <tr
          class="packing-slip-tb-head"
          style="background-color: #d89700; color: white"
        >
          <th class="w-100">Product</th>
          <th class="text-center" style="padding-right: 2rem">Quantity</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, i) in order.items" :key="i">
          <td>
            <b>{{ item.name }}</b>
          </td>
          <td class="text-center" style="padding-right: 2rem">
            {{ item.quantity }}
          </td>
        </tr>
      </tbody>
    </table>
    <div class="d-flex align-items-center" v-if="order.gift_page_url" style="
      position: absolute;
      bottom: 0;
      left: 22mm;"
    >
      <img
        :src="order.gift_page_url"
        @load="docReady"
        style="
          width: 20mm;
          height: 20mm;
          border: 1px dashed black;
        "
      />
      <div class="p-2 text-center">
        <div class="p-2 rounded text-white" style="background: #A3111B;">
          <p class="m-0" style="font-size: 10pt;"><b>ATTENTION</b></p>
          <p class="m-0" style="font-size: 8pt;">Pesanan ini terdapat voice message, gunting QR code di samping,<br>lalu tempelkan pada kartu yang sudah disediakan Whizliz</p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "PackingSlip",
  data() {
    return {
      order: null,
    };
  },
  mounted() {
    const id = this.$route.params.id;
    document.title = "packing-slip-#" + id;
    this.getOrderDetail(id);
  },
  methods: {
    async getOrderDetail(id) {
      try {
        const res = await this.$api.order.getOrderDetail(id);
        if (res.status === 200) {
          this.order = res.data.data;
          if (!this.order.gift_page_url)
            this.docReady();
        }
      } catch (e) {
        console.error(e);
      }
    },
    docReady() {
      this.$nextTick(() => window.print())
    },
  },
};
</script>
<style scoped>
table {
  width: 100%;
}

table tr th,
td {
  padding: 0.5em;
}

table tr th {
  font-size: 2rem;
}

td table tr td {
  padding: 0;
  padding-left: 1em;
}
</style>
